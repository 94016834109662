import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import logo from "../assets/logo.png";
import LayoutMargin from "../layout/LayoutMargin";
import Navigation from "./Navigation";
import MobNavigation from "./MobNavigation";
import { AiOutlineMenu, AiOutlineCloseCircle } from "react-icons/ai";

const Wrapper = styled.div`
  height: auto;
  max-height: 10rem;
  padding: 1rem 0;

  @media (max-width: 530px) {
    padding: 0.5rem 0;
  }
`;
const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 2rem;
  cursor: pointer;
`;
const Logo = styled.img`
  height: auto;
  width: 8rem;

  @media (max-width: 530px) {
    width: 6rem;
  }
`;
const HamContainer = styled.div`
  display: none;

  @media (max-width: 930px) {
    display: block;
    margin-right: 2rem;
  }
`;

const Header = () => {
  const navigate = useNavigate();
  const [hamburger, setHamburger] = useState(false);

  const openHamburger = (
    <AiOutlineMenu
      size="40px"
      color="black"
      onClick={() => {
        setHamburger(true);
      }}
    />
  );

  const closeHamburger = (
    <AiOutlineCloseCircle
      size="40px"
      color="black"
      onClick={() => {
        setHamburger(false);
      }}
    />
  );

  const handleLogoClick = () => {
    navigate("/");
  };
  return (
    <Wrapper>
      {/* <LayoutMargin> */}
      <HeaderContainer>
        <LogoContainer>
          <Logo
            src={logo}
            alt="logo"
            onClick={() => {
              handleLogoClick();
            }}
          ></Logo>
        </LogoContainer>
        <HamContainer>
          {hamburger ? closeHamburger : openHamburger}
        </HamContainer>
        {hamburger ? (
          <MobNavigation setHamburger={setHamburger} />
        ) : (
          <Navigation />
        )}
      </HeaderContainer>
      {/* </LayoutMargin> */}
    </Wrapper>
  );
};

export default Header;
