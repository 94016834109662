import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import logo from "../assets/logo.png";
import LayoutMargin from "../layout/LayoutMargin";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

const Wrapper = styled.div`
  margin-top: auto;
  padding: 2rem 0;
  height: auto;
  max-height: 40rem;
  display: flex;
  justify-content: space-between;
  background: linear-gradient(
    180deg,
    rgb(182, 43, 43) 15%,
    rgba(151, 0, 0, 1) 30%,
    rgba(120, 0, 0, 1) 45%,
    rgba(90, 0, 0, 1) 55%,
    rgba(62, 0, 2, 1) 70%,
    rgba(28, 0, 1, 1) 85%,
    rgba(0, 0, 0, 1) 100%
  );
`;
const FooterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 2rem;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;
const LogoAboutContainer = styled.div`
  flex: 1;

  @media (max-width: 600px) {
    display: flex;
    align-items: flex-start;
    margin-bottom: 2rem;
  }
`;
const FooterLogo = styled.img`
  height: auto;
  width: 8rem;
  margin: 2rem 0 2rem 0;
  cursor: pointer;

  @media (max-width: 600px) {
    margin: 0.5rem 1rem;
  }
  @media (max-width: 530px) {
    width: 6rem;
  }
`;
const About = styled.div`
  color: white;
  font-size: 1.1rem;

  @media (max-width: 530px) {
    font-size: 0.8rem;
    padding-right: 0.7rem;
  }
`;
const FooterColumnWrapper = styled.div`
  flex: 2;
  display: flex;
  justify-content: space-around;
  padding: 1rem 4rem;
`;
const FooterColumn = styled.div`
  color: white;
  padding: 0.75rem;
`;
const FirstFooterColumn = styled(FooterColumn)`
  padding-left: 5rem;

  @media (max-width: 930px) {
    padding: 0 2.5rem;
    padding-right: 1rem;
  }
  @media (max-width: 550px) {
    padding: 0 0.5rem;
    padding-right: 0rem;
  }
`;
const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const FooterLink = styled(NavLink)`
  text-align: center;
  text-decoration: none;
  line-height: 0.8rem;
  cursor: pointer;
  color: white;
  padding: 1rem;
  padding-left: 0;
  :hover {
    scale: 1.1;
  }
`;
const ContactLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 1.1rem;
`;
const FooterSubsection = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`;

const Footer = () => {
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate("/");
  };
  return (
    <Wrapper>
      <LayoutMargin width={"fullWidth"}>
        <FooterContainer>
          <LogoAboutContainer>
            <FooterLogo
              src={logo}
              alt="logo"
              onClick={() => {
                handleLogoClick();
              }}
            ></FooterLogo>
            <About>
              Unleash your business potential with our tech solutions. From
              dynamic web development to cutting-edge machine learning, we're
              your partners in innovation, propelling your success through
              personalized strategies and data-driven excellence.
            </About>
          </LogoAboutContainer>
          <FooterColumnWrapper>
            <FirstFooterColumn>
              <FooterSubsection
                style={{ paddingBottom: "3rem", paddingTop: "0.75rem" }}
              >
                {/* <div>
                  <h3>Quick Links</h3>
                </div> */}
                <LinkContainer>
                  <FooterLink to="/home">Home</FooterLink>
                  <FooterLink to="/about">About</FooterLink>
                  <FooterLink to="/contact-us">Contact Us</FooterLink>
                </LinkContainer>
              </FooterSubsection>
            </FirstFooterColumn>
            <FooterColumn>
              {/* <LinkContainer>
                <FooterLink to="/services/data-analytics">Analytics</FooterLink>
                <FooterLink to="/services/ai-ml">AI/ML</FooterLink>
                <FooterLink to="/services/web-dev">Web Development</FooterLink>
              </LinkContainer> */}
              <LinkContainer>
                {/* <div>
                  <h3>Contact</h3>
                </div> */}
                <ContactLink>
                  <MailOutlineIcon
                    style={{ marginRight: "0.5rem", marginTop: "0.2rem" }}
                  />
                  contact@picktofa.com
                </ContactLink>
              </LinkContainer>
            </FooterColumn>
          </FooterColumnWrapper>
        </FooterContainer>
      </LayoutMargin>
    </Wrapper>
  );
};

export default Footer;
