import "./App.css";
import { Route, Routes } from "react-router-dom";
//import Homepage from "./components/Homepage";
import Layout from "./layout/Layout";
import About from "./pages/About";
import ContactUs from "./pages/ContactUs";
import Services from "./pages/Services";
//import DataAnalytics from "./pages/DataAnalytics";
//import AiMl from "./pages/AiMl";
//import WebDev from "./pages/WebDev";
import ScrollToTop from "./layout/ScrollToTop";

function App() {
  return (
    <div style={{ width: "100%" }}>
      <ScrollToTop />
      <Layout>
        <Routes>
          <Route
            exact
            path="/"
            element={<Services />}
          />
          <Route
            exact
            path="/about"
            element={<About />}
          />
          <Route
            exact
            path="/home"
            element={<Services />}
          />
          {/* <Route
            exact
            path="/services/data-analytics"
            element={<DataAnalytics />}
          />
          <Route
            exact
            path="/services/ai-ml"
            element={<AiMl />}
          />
          <Route
            exact
            path="/services/web-dev"
            element={<WebDev />}
          /> */}
          <Route
            exact
            path="/contact-us"
            element={<ContactUs />}
          />
          <Route
            exact
            path="*"
            element={<h1>Oops! Looks like its not available</h1>}
          />
        </Routes>
      </Layout>
    </div>
  );
}

export default App;
