import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

const MobLinkContainer = styled.div`
  display: none;

  @media (max-width: 930px) {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    top: 7rem;
    background-color: rgb(182, 43, 43);
    box-shadow: 0px 9px 9px rgba(0, 0, 0, 0.1);
    z-index: 1;
  }
  @media (max-width: 530px) {
    top: 6rem;
  }
  @media (max-width: 410px) {
    top: 4.5rem;
  }
`;
const MobIntroLink = styled(NavLink)`
  text-align: center;
  text-decoration: none;
  line-height: 2rem;
  font-weight: bold;
  font-size: 1.2rem;
  cursor: pointer;
  color: white;
  padding: 1rem 2.5rem;

  :hover {
    scale: 1.2;
  }
`;

const MobNavigation = ({ setHamburger }) => {
  const handleClick = () => {
    setHamburger(false);
  };
  // document.addEventListener("click", function (e) {
  //   if (
  //     document.getElementById("mobDiv") &&
  //     !document.getElementById("mobDiv").contains(e.target)
  //   ) {
  //     handleClick();
  //   }
  // });

  return (
    <MobLinkContainer id="mobDiv">
      <MobIntroLink
        to="/home"
        onClick={() => {
          handleClick();
        }}
      >
        Home
      </MobIntroLink>
      <MobIntroLink
        to="/about"
        onClick={() => {
          handleClick();
        }}
      >
        About
      </MobIntroLink>
      <MobIntroLink
        to="/contact-us"
        onClick={() => {
          handleClick();
        }}
      >
        Contact Us
      </MobIntroLink>
    </MobLinkContainer>
  );
};

export default MobNavigation;
