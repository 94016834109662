import { React, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import servicesImg from "../assets/servicesImg.jpg";
import dataAnalytics from "../assets/dataAnalytics.jpg";
import aiMl from "../assets/aiMl.jpg";
import webDev from "../assets/webDev.jpg";
import DataAnalytics from "./DataAnalytics";
import AiMl from "./AiMl";
import WebDev from "./WebDev";

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 5rem;
`;
const IntroWrapper = styled.div`
  width: 100%;
  height: 45vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: rgb(182 43 43);
  position: relative;
`;
const IntroContainer = styled.div`
  width: 100%;
  height: 70vh;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background: linear-gradient(180deg, rgb(182 43 43) 50%, white 50%);

  @media (max-width: 930px) {
    flex-direction: column;
    height: 70vh;
    justify-content: center;
    align-items: flex-start;
    background: linear-gradient(180deg, rgb(182 43 43) 58%, white 42%);
  }
  @media (max-width: 530px) {
    height: 50vh;
  }
  @media (max-width: 410px) {
    height: 40vh;
  }
`;
const slideInAnimation = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
`;
const slideInAnimationImg = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;
const IntroText = styled.p`
  color: white;
  font-size: 3rem;
  margin-top: -1.5rem;
  position: absolute;
  bottom: 0;
  left: 5rem;
  font-weight: 600;
  z-index: 2;

  // animation: ${slideInAnimation} 1s ease-in-out;

  @media (max-width: 930px) {
    margin-top: 0;
    margin-left: 2rem;
  }
  @media (max-width: 530px) {
    margin-bottom: 1rem;
    font-size: 2.5rem;
  }
  @media (max-width: 410px) {
    margin-bottom: 0.5rem;
    font-size: 2rem;
  }
`;
const IntrImg = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  transform: scaleX(-1);
  // animation: ${slideInAnimationImg} 1s ease-in-out;

  @media (max-width: 930px) {
    margin-left: 2rem;
  }
  @media (max-width: 530px) {
    width: 22rem;
  }
  @media (max-width: 410px) {
    width: 19rem;
  }
`;
const ServiceCategoryBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 930px) {
    margin-top: 5rem;
  }
  @media (max-width: 530px) {
    margin-top: 0rem;
    margin-bottom: 3rem;
  }
`;
const ServiceTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
const ServiceText = styled.p`
  color: black;
  font-size: 3rem;

  @media (max-width: 410px) {
    font-size: 2rem;
  }
`;
const ServiceCatWrapper = styled.div`
  margin-top: 8rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  @media (max-width: 930px) {
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }
`;
const CardWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border: 2px solid rgb(182 43 43);
  border-radius: 1rem;
  max-width: 25vw;
  height: 25vh;
  position: relative;
  cursor: pointer;

  ${({ active }) =>
    active &&
    `
    border: 3px solid rgb(182 43 43); /* Add a border for the active card */
    box-shadow: 0 0 15px rgb(182 43 43); /* Add a box shadow for the active card */
  `}

  @media (max-width: 930px) {
    max-width: 60vw;
    margin-bottom: 3rem;
  }
`;
const Backdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 1rem;
`;
const CardImg = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 1rem;
  object-fit: cover;

  @media (max-width: 930px) {
    border-radius: 1rem;
  }
`;
const CardText = styled.p`
  position: absolute;
  top: 20%;
  font-weight: 600;
  color: white;
  font-size: 2rem;
  z-index: 2;

  @media (max-width: 930px) {
    border-radius: 1rem;
  }
`;
const ServiceDetailContainer = styled.div`
  margin-top: 3rem;
  width: 100%;
  display: flex;
  justify-content: center;
`;
const FlipContainer = styled.div`
  width: 100%;
  height: 30%;
  perspective: 1000px;
  position: absolute;
  bottom: 0;
`;
const FlipCard = styled.div`
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.5s;
  &:hover {
    transform: rotateY(180deg);
  }
`;
const Front = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  color: black;
  position: absolute;
  backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 1rem 1rem;
  cursor: pointer;
`;
const Back = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgb(182 43 43);
  color: white;
  position: absolute;
  backface-visibility: hidden;
  transform: rotateY(180deg);
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid rgb(182 43 43);
  border-radius: 0 0 1rem 1rem;
  cursor: pointer;
`;
const Content = styled.p`
  text-align: center;
`;

const Services = () => {
  const navigate = useNavigate();
  const [activeCard, setActiveCard] = useState("dataAnalytics");

  const dataAnalyticsContent = () => {
    return (
      <div>
        <DataAnalytics />
      </div>
    );
  };
  const aiMlContent = () => {
    return (
      <div>
        <AiMl />
      </div>
    );
  };
  const webDevContent = () => {
    return (
      <div>
        <WebDev />
      </div>
    );
  };

  const handleCardClick = (card) => {
    setActiveCard(card);
  };

  return (
    <Wrapper>
      {/* <IntroContainer>
        <IntroText>Services</IntroText>
        <IntrImg
          src={servicesImg}
          alt="Services"
        ></IntrImg>
      </IntroContainer> */}
      <IntroWrapper>
        <IntroText>Services</IntroText>
        <IntrImg
          src={servicesImg}
          alt="Services"
        ></IntrImg>
      </IntroWrapper>
      <ServiceCategoryBlock>
        <ServiceCatWrapper>
          <CardWrapper
            active={activeCard === "dataAnalytics"}
            onClick={() => handleCardClick("dataAnalytics")}
          >
            <CardImg
              src={dataAnalytics}
              alt="Services"
            ></CardImg>
            <Backdrop />
            <CardText>Data Analytics</CardText>
          </CardWrapper>
          <CardWrapper
            active={activeCard === "aiMl"}
            onClick={() => handleCardClick("aiMl")}
          >
            <CardImg
              src={aiMl}
              alt="Services"
            ></CardImg>
            <Backdrop />
            <CardText>AI ML</CardText>
          </CardWrapper>
          <CardWrapper
            active={activeCard === "webDev"}
            onClick={() => handleCardClick("webDev")}
          >
            <CardImg
              src={webDev}
              alt="Services"
            ></CardImg>
            <Backdrop />
            <CardText>Web Development</CardText>
          </CardWrapper>
        </ServiceCatWrapper>
        <ServiceDetailContainer>
          {activeCard === "dataAnalytics" && dataAnalyticsContent()}
          {activeCard === "aiMl" && aiMlContent()}
          {activeCard === "webDev" && webDevContent()}
        </ServiceDetailContainer>
      </ServiceCategoryBlock>
    </Wrapper>
  );
};

export default Services;
