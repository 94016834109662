import React from "react";
import styled, { keyframes } from "styled-components";
import aboutUs from "../assets/aboutUs.jpg";
import aboutUs2 from "../assets/aboutUs2.jpg";
import aboutUs3 from "../assets/aboutUs3.jpg";
import LayoutMargin from "../layout/LayoutMargin";

const Wrapper = styled.div`
  width: 100%;
  height: auto;
`;
const IntroWrapper = styled.div`
  width: 100%;
  height: 45vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: rgb(182 43 43);
  position: relative;
`;
const IntroContainer = styled.div`
  width: 100%;
  height: 70vh;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  @media (max-width: 930px) {
    flex-direction: column;
    height: 80vh;
    justify-content: center;
    align-items: flex-start;
  }
  @media (max-width: 530px) {
    height: 51vh;
  }
  @media (max-width: 410px) {
    height: 40vh;
  }
`;
const AboutMissionContainer = styled.div`
  width: 100%;
  height: auto;
  padding: 5rem 0;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;

  @media (max-width: 930px) {
    height: auto;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5rem;
  }
  @media (max-width: 530px) {
    margin-bottom: 1rem;
    padding-top: 1rem;
  }
`;
const slideInAnimation = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
`;
const slideInAnimationRTL = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;
const IntroText = styled.p`
  color: white;
  font-size: 3rem;
  margin-top: -1.5rem;
  position: absolute;
  bottom: 0;
  left: 5rem;
  font-weight: 600;
  z-index: 2;

  // animation: ${slideInAnimation} 1s ease-in-out;

  @media (max-width: 930px) {
    margin-top: 0;
    margin-left: 2rem;
    margin-bottom: 1rem;
  }
  @media (max-width: 530px) {
    margin-bottom: 1rem;
    font-size: 2.5rem;
  }
  @media (max-width: 410px) {
    margin-bottom: 0.5rem;
    font-size: 2rem;
  }
`;
const AboutContentText = styled.p`
  color: white;
  font-size: 3rem;
  margin-top: -1.5rem;

  // animation: ${slideInAnimation} 1s ease-in-out;

  @media (max-width: 930px) {
    margin-top: 0;
    margin-left: 2rem;
    margin-bottom: 1rem;
  }
  @media (max-width: 530px) {
    margin-bottom: 1rem;
    font-size: 2.5rem;
  }
  @media (max-width: 410px) {
    margin-bottom: 0.5rem;
    font-size: 2rem;
  }
`;
const IntrImg = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  transform: scaleX(-1);

  // animation: ${slideInAnimationRTL} 1s ease-in-out;

  @media (max-width: 930px) {
    margin-left: 2rem;
  }
  @media (max-width: 530px) {
    width: 22rem;
  }
  @media (max-width: 410px) {
    width: 19rem;
  }
`;
const AboutContentImg = styled.img`
  height: auto;
  width: 30rem;
`;
const AboutText = styled.p`
  color: black;
  font-size: 3rem;
  margin-top: 0rem;
  margin-bottom: 0rem;
  animation: ${slideInAnimationRTL} 1s ease-in-out;

  @media (max-width: 930px) {
    order: 1;
  }
  @media (max-width: 410px) {
    font-size: 2rem;
  }
`;
const AboutImg = styled.img`
  height: auto;
  width: 30rem;
  animation: ${slideInAnimation} 1s ease-in-out;

  @media (max-width: 930px) {
    order: 2;
  }
  @media (max-width: 530px) {
    width: 22rem;
  }
  @media (max-width: 410px) {
    width: 19rem;
  }
`;
const AboutWrapper = styled.div`
  margin: 4rem 0;
  padding: 2rem 0;
  background-color: rgb(201 200 200 / 10%);

  @media (max-width: 410px) {
    margin: 1rem 0;
  }
`;
const AboutTextWrapper = styled.div`
  margin: 2rem 2rem 2rem 0;
  max-width: 35rem;
  animation: ${slideInAnimationRTL} 1s ease-in-out;

  @media (max-width: 1200px) {
    margin: 2rem;
  }
  @media (max-width: 930px) {
    text-align: center;
    max-width: 45rem;
  }
`;
const IntroTextWrapper = styled.div`
  margin: 2rem 2rem 2rem 0;
  max-width: 35rem;
  animation: ${slideInAnimation} 1s ease-in-out;

  @media (max-width: 1200px) {
    margin: 2rem;
  }
`;
const ParaText = styled.p`
  font-size: 1.2rem;

  @media (max-width: 930px) {
    text-align: center;
  }
  @media (max-width: 410px) {
    font-size: 1rem;
  }
`;
const AboutMissionWrapper = styled.div`
  margin: 4rem 0;
  padding: 1rem 0;
  background-color: white;

  @media (max-width: 530px) {
    margin: 2rem 0;
  }
  @media (max-width: 410px) {
    margin: 4rem 0;
  }
`;
const ChooseWrapper = styled.div`
  width: 35%;
  animation: ${slideInAnimation} 1s ease-in-out;

  @media (max-width: 930px) {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 75%;
    margin-bottom: 2rem;
  }
`;
const MissionWrapper = styled.div`
  width: 35%;
  animation: ${slideInAnimationRTL} 1s ease-in-out;

  @media (max-width: 930px) {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 75%;
  }
`;
const AboutMissionText = styled.p`
  color: black;
  font-size: 3rem;
  margin-top: 0rem;
  margin-bottom: 0rem;

  @media (max-width: 530px) {
    margin-bottom: 1rem;
    font-size: 2.5rem;
  }
  @media (max-width: 410px) {
    margin-bottom: 0rem;
    font-size: 2rem;
  }
`;

const About = () => {
  return (
    <Wrapper>
      {/* <IntroWrapper>
        <IntroContainer>
          <IntroText>About Us</IntroText>
          <IntrImg
            src={aboutUs2}
            alt="Services"
          ></IntrImg>
        </IntroContainer>
      </IntroWrapper> */}
      <IntroWrapper>
        <IntroText>About Us</IntroText>
        <IntrImg
          src={aboutUs2}
          alt="Services"
        ></IntrImg>
      </IntroWrapper>
      <AboutWrapper>
        <LayoutMargin>
          <AboutMissionContainer>
            <AboutImg
              src={aboutUs3}
              alt="Services"
            ></AboutImg>{" "}
            <AboutTextWrapper>
              <AboutText>Who We Are</AboutText>
              <ParaText>
                We are a team of skilled engineers dedicated to innovation and
                excellence. Our diverse talents coalesce to form a dynamic force
                committed to addressing complex challenges. With a passion for
                technology and a collaborative spirit, we foster an environment
                that values creativity and expertise. As individuals, we bring
                unique perspectives; together, we are a cohesive unit united by
                a shared commitment to delivering quality solutions and
                exceeding expectations.
              </ParaText>
            </AboutTextWrapper>
          </AboutMissionContainer>
          <AboutMissionContainer>
            <AboutTextWrapper>
              <AboutContentText style={{ color: "black" }}>
                What We Do
              </AboutContentText>
              <ParaText>
                At our core, we specialize in providing cutting-edge solutions
                in web development, machine learning, and data analytics. From
                crafting intuitive user interfaces to implementing advanced
                machine learning algorithms, we excel in delivering
                comprehensive services that cater to the evolving needs of our
                clients.
              </ParaText>
            </AboutTextWrapper>
            <AboutContentImg
              src={aboutUs}
              alt="Services"
              style={{ marginLeft: "0" }}
            ></AboutContentImg>
          </AboutMissionContainer>
        </LayoutMargin>
      </AboutWrapper>
      <AboutMissionWrapper>
        <LayoutMargin>
          <AboutMissionContainer style={{ marginBottom: "1rem" }}>
            <ChooseWrapper>
              <AboutMissionText>Why Choose Us</AboutMissionText>
              <ParaText>
                Choosing us means selecting a partner dedicated to your success.
                Our team's proficiency, commitment to quality, and
                client-centric approach set us apart. We prioritize clear
                communication, transparency, and a collaborative mindset,
                ensuring that our clients not only receive exceptional solutions
                but also enjoy a seamless and rewarding partnership.
              </ParaText>
            </ChooseWrapper>
            <MissionWrapper>
              <AboutMissionText>Our Mission</AboutMissionText>
              <ParaText>
                Our mission is to empower businesses through innovative
                technology solutions. We strive to be at the forefront of
                technological advancements, applying our skills to drive
                positive change for our clients. By fostering a culture of
                continuous learning and adaptability, we aim to create lasting
                value for our clients, partners, and the community. Our mission
                is rooted in the belief that technology, when harnessed
                effectively, can transform challenges into opportunities for
                growth and success.
              </ParaText>
            </MissionWrapper>
          </AboutMissionContainer>
        </LayoutMargin>
      </AboutMissionWrapper>
    </Wrapper>
  );
};

export default About;
