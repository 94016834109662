import React from "react";
import styled, { keyframes } from "styled-components";
import webDev from "../assets/webDev.jpg";
import webDevAbout from "../assets/webDevAbout.jpg";
import LayoutMargin from "../layout/LayoutMargin";

const Wrapper = styled.div`
  width: 100%;
  height: auto;
`;
const IntroWrapper = styled.div`
  width: 100%;
  height: 70vh;
  background: linear-gradient(180deg, rgb(182 43 43) 50%, white 50%);

  @media (max-width: 930px) {
    height: 78vh;
    background: linear-gradient(180deg, rgb(182 43 43) 58%, white 42%);
  }
  @media (max-width: 530px) {
    height: 50vh;
  }
  @media (max-width: 410px) {
    height: 40vh;
  }
`;
const IntroContainer = styled.div`
  width: 100%;
  height: 70vh;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  @media (max-width: 930px) {
    flex-direction: column;
    height: 80vh;
    justify-content: center;
    align-items: flex-start;
  }
  @media (max-width: 530px) {
    height: 51vh;
  }
  @media (max-width: 410px) {
    height: 40vh;
  }
`;
const AboutContainer = styled.div`
  width: 100%;
  height: auto;
  padding: 5rem 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  @media (max-width: 930px) {
    height: auto;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5rem;
  }
  @media (max-width: 530px) {
    margin-bottom: 1rem;
    padding-top: 1rem;
  }
`;
const slideInAnimation = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
`;
const slideInAnimationRTL = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;
const IntroText = styled.p`
  color: white;
  font-size: 3rem;
  margin-top: -1.5rem;
  animation: ${slideInAnimation} 1s ease-in-out;

  @media (max-width: 1000px) {
    padding-top: 3rem;
    margin-top: -9.5rem;
  }
  @media (max-width: 930px) {
    margin-top: 0;
    margin-left: 2rem;
    margin-bottom: 1rem;
  }
  @media (max-width: 530px) {
    padding-top: 0rem;
    margin-bottom: 1rem;
    font-size: 2.5rem;
  }
  @media (max-width: 410px) {
    margin-bottom: 0.5rem;
    font-size: 2rem;
  }
`;
const IntrImg = styled.img`
  height: auto;
  width: 30rem;
  animation: ${slideInAnimationRTL} 1s ease-in-out;

  @media (max-width: 930px) {
    margin-left: 2rem;
  }
  @media (max-width: 530px) {
    width: 22rem;
  }
  @media (max-width: 410px) {
    width: 19rem;
  }
`;
const AboutText = styled.p`
  color: black;
  font-size: 3rem;
  margin-top: 0rem;
  margin-bottom: 0rem;
  animation: ${slideInAnimationRTL} 1s ease-in-out;

  @media (max-width: 930px) {
    order: 1;
  }
  @media (max-width: 410px) {
    font-size: 2rem;
  }
`;
const AboutImg = styled.img`
  height: auto;
  width: 30rem;
  animation: ${slideInAnimation} 1s ease-in-out;

  @media (max-width: 930px) {
    order: 2;
  }
  @media (max-width: 530px) {
    width: 22rem;
  }
  @media (max-width: 410px) {
    width: 19rem;
  }
`;
const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 2rem 5rem;

  @media (max-width: 930px) {
    flex-wrap: no-wrap;
    flex-direction: column;
  }
  @media (max-width: 530px) {
    margin: 2rem;
    margin-bottom: 5rem;
  }
  @media (max-width: 410px) {
    margin: 2rem;
    margin-bottom: 5rem;
  }
`;

const Box = styled.div`
  flex: 0 1 30%;
  margin-bottom: 20px;
  background-color: white;
  border: 1px solid #ddd;
  box-sizing: border-box;
  box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.1);
  min-height: 10rem;
  min-width: 10rem;
  padding: 1.5rem;
`;

const LastRowBox = styled.div`
  // flex-grow: 1;
  // flex-basis: calc(30% * 3);
  flex: 0 1 47.5%;
  margin-bottom: 20px;
  background-color: white;
  border: 1px solid #ddd;
  box-sizing: border-box;
  box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.1);
  min-height: 10rem;
  min-width: 10rem;
  padding: 1.5rem;
`;
const TextWrapper = styled.div`
  text-align: center;
`;
const ParaText = styled.p`
  font-size: 1.2rem;

  @media (max-width: 930px) {
    text-align: center;
  }
  @media (max-width: 410px) {
    font-size: 1rem;
  }
`;
const AboutWrapper = styled.div`
  margin: 4rem 0;
  padding: 2rem 0;
  background-color: rgb(201 200 200 / 10%);

  @media (max-width: 530px) {
    margin-top: 0rem;
    padding-top: 2rem;
  }
  @media (max-width: 410px) {
    margin: 1rem 0;
  }
`;
const AboutTextWrapper = styled.div`
  margin: 2rem 2rem 2rem 0;
  max-width: 35rem;
  animation: ${slideInAnimationRTL} 1s ease-in-out;

  @media (max-width: 1200px) {
    margin: 2rem;
  }
  @media (max-width: 930px) {
    text-align: center;
    max-width: 45rem;
  }
  @media (max-width: 530px) {
    margin-bottom: 1rem;
    font-size: 2.5rem;
  }
  @media (max-width: 410px) {
    margin-bottom: 0rem;
    font-size: 2rem;
  }
`;

const WebDev = () => {
  return (
    <Wrapper>
      {/* <IntroWrapper>
        <IntroContainer>
          <IntroText>Web Development</IntroText>
          <IntrImg
            src={webDev}
            alt="Services"
          ></IntrImg>
        </IntroContainer>
      </IntroWrapper> */}
      <AboutWrapper>
        <LayoutMargin>
          <AboutContainer>
            <AboutImg
              src={webDevAbout}
              alt="Services"
            ></AboutImg>
            <AboutTextWrapper>
              <AboutText>Web Development</AboutText>
              <ParaText>
                Web development is pivotal for businesses, establishing an
                online presence, engaging customers, and driving revenue. It
                ensures seamless user experiences, facilitates e-commerce
                transactions, and enables efficient communication. In the
                digital age, a well-crafted website enhances brand visibility,
                credibility, and accessibility, contributing to overall business
                success.
              </ParaText>
            </AboutTextWrapper>
          </AboutContainer>
        </LayoutMargin>
      </AboutWrapper>
      <LayoutMargin>
        <TextWrapper style={{ marginBottom: "4rem", marginTop: "7rem" }}>
          <h1>We provide a variety of Web Development services.</h1>
        </TextWrapper>
        <Container>
          <Box>
            <TextWrapper>
              <h3>MERN Stack Development: </h3>
            </TextWrapper>
            <TextWrapper>
              <ParaText>
                Transform your digital presence with cutting-edge MERN (MongoDB,
                Express.js, React, Node.js) stack development, delivering
                high-performance, scalable, and feature-rich web applications
                that resonate with your audience.
              </ParaText>
            </TextWrapper>
          </Box>
          <Box>
            <TextWrapper>
              <h3>Frontend Development: </h3>
            </TextWrapper>
            <TextWrapper>
              <ParaText>
                Transform your ideas into captivating user experiences with our
                React frontend development. Craft visually stunning, responsive
                interfaces that elevate user engagement and satisfaction.
              </ParaText>
            </TextWrapper>
          </Box>
          <Box>
            <TextWrapper>
              <h3>Unit Testing: </h3>
            </TextWrapper>
            <TextWrapper>
              <ParaText>
                Ensure the robustness of your codebase through rigorous unit
                testing, guaranteeing the reliability and functionality of each
                component, and enhancing the overall quality of your web
                applications.
              </ParaText>
            </TextWrapper>
          </Box>
          <LastRowBox>
            <TextWrapper>
              <h3>New Relic Integration: </h3>
            </TextWrapper>
            <TextWrapper>
              <ParaText>
                Optimize performance and gain real-time insights into your web
                applications by seamlessly integrating with New Relic. Monitor,
                analyze, and enhance user experiences for optimal digital
                performance.
              </ParaText>
            </TextWrapper>
          </LastRowBox>
          <LastRowBox>
            <TextWrapper>
              <h3>AWS Architecture Setup: </h3>
            </TextWrapper>
            <TextWrapper>
              <ParaText>
                Build a scalable and secure foundation for your web applications
                with AWS architecture setup. Leverage the power of Amazon Web
                Services to ensure high availability, fault tolerance, and
                efficient resource utilization.
              </ParaText>
            </TextWrapper>
          </LastRowBox>
        </Container>
      </LayoutMargin>
    </Wrapper>
  );
};

export default WebDev;
