import React from "react";
import styled, { keyframes } from "styled-components";
import dataAnalytics from "../assets/dataAnalytics.jpg";
import dataAnalyticsAbout from "../assets/dataAnalyticsAbout.webp";
import LayoutMargin from "../layout/LayoutMargin";

const Wrapper = styled.div`
  width: 100%;
  height: auto;
`;
const IntroWrapper = styled.div`
  width: 100%;
  height: 70vh;
  background: linear-gradient(180deg, rgb(182 43 43) 50%, white 50%);

  @media (max-width: 930px) {
    height: 78vh;
    background: linear-gradient(180deg, rgb(182 43 43) 58%, white 42%);
  }
  @media (max-width: 530px) {
    height: 50vh;
  }
  @media (max-width: 410px) {
    height: 40vh;
  }
`;
const IntroContainer = styled.div`
  width: 100%;
  height: 70vh;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  @media (max-width: 930px) {
    flex-direction: column;
    height: 80vh;
    justify-content: center;
    align-items: flex-start;
  }
  @media (max-width: 530px) {
    height: 51vh;
  }
  @media (max-width: 410px) {
    height: 40vh;
  }
`;
const AboutContainer = styled.div`
  width: 100%;
  height: auto;
  padding: 5rem 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  @media (max-width: 930px) {
    height: auto;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5rem;
  }
  @media (max-width: 530px) {
    margin-bottom: 1rem;
    padding-top: 1rem;
  }
`;
const slideInAnimation = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
`;
const slideInAnimationRTL = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;
const IntroText = styled.p`
  color: white;
  font-size: 3rem;
  margin-top: -1.5rem;
  animation: ${slideInAnimation} 1s ease-in-out;

  @media (max-width: 930px) {
    margin-top: 0;
    margin-left: 2rem;
    margin-bottom: 1rem;
  }
  @media (max-width: 530px) {
    margin-bottom: 1rem;
    font-size: 2.5rem;
  }
  @media (max-width: 410px) {
    margin-bottom: 0.5rem;
    font-size: 2rem;
  }
`;
const IntrImg = styled.img`
  height: auto;
  width: 30rem;
  animation: ${slideInAnimationRTL} 1s ease-in-out;

  @media (max-width: 930px) {
    margin-left: 2rem;
  }
  @media (max-width: 530px) {
    width: 22rem;
  }
  @media (max-width: 410px) {
    width: 19rem;
  }
`;
const AboutText = styled.p`
  color: black;
  font-size: 3rem;
  margin-top: 0rem;
  margin-bottom: 0rem;
  animation: ${slideInAnimationRTL} 1s ease-in-out;

  @media (max-width: 930px) {
    order: 1;
  }
  @media (max-width: 410px) {
    font-size: 2rem;
  }
`;
const AboutImg = styled.img`
  height: auto;
  width: 30rem;
  animation: ${slideInAnimation} 1s ease-in-out;

  @media (max-width: 930px) {
    order: 2;
  }
  @media (max-width: 530px) {
    width: 22rem;
  }
  @media (max-width: 410px) {
    width: 19rem;
  }
`;
const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 2rem 5rem;

  @media (max-width: 930px) {
    flex-wrap: no-wrap;
    flex-direction: column;
  }
  @media (max-width: 530px) {
    margin: 2rem;
    margin-bottom: 5rem;
  }
  @media (max-width: 410px) {
    margin: 2rem;
    margin-bottom: 5rem;
  }
`;

const Box = styled.div`
  flex: 0 1 30%;
  margin-bottom: 20px;
  background-color: white;
  border: 1px solid #ddd;
  box-sizing: border-box;
  box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.1);
  min-height: 10rem;
  min-width: 10rem;
  padding: 1.5rem;
`;

const LastRowBox = styled.div`
  flex-grow: 1;
  flex-basis: calc(30% * 3);
  margin-bottom: 20px;
  background-color: white;
  border: 1px solid #ddd;
  box-sizing: border-box;
  box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.1);
  min-height: 10rem;
  min-width: 10rem;
  padding: 1.5rem;
`;
const TextWrapper = styled.div`
  text-align: center;
`;
const ParaText = styled.p`
  font-size: 1.2rem;

  @media (max-width: 930px) {
    text-align: center;
  }
  @media (max-width: 410px) {
    font-size: 1rem;
  }
`;
const AboutWrapper = styled.div`
  margin: 4rem 0;
  padding: 2rem 0;
  background-color: rgb(201 200 200 / 10%);

  @media (max-width: 530px) {
    margin-top: 0rem;
    padding-top: 2rem;
  }
  @media (max-width: 410px) {
    margin: 1rem 0;
  }
`;
const AboutTextWrapper = styled.div`
  margin: 2rem 2rem 2rem 0;
  max-width: 35rem;
  animation: ${slideInAnimationRTL} 1s ease-in-out;

  @media (max-width: 1200px) {
    margin: 2rem;
  }
  @media (max-width: 930px) {
    text-align: center;
    max-width: 45rem;
  }
  @media (max-width: 530px) {
    margin-bottom: 1rem;
    font-size: 2.5rem;
  }
  @media (max-width: 410px) {
    margin-bottom: 0rem;
    font-size: 2rem;
  }
`;

const DataAnalytics = () => {
  return (
    <Wrapper>
      {/* <IntroWrapper>
        <IntroContainer>
          <IntroText>Data Analytics</IntroText>
          <IntrImg
            src={dataAnalytics}
            alt="Services"
          ></IntrImg>
        </IntroContainer>
      </IntroWrapper> */}
      <AboutWrapper>
        <LayoutMargin>
          <AboutContainer>
            <AboutImg
              src={dataAnalyticsAbout}
              alt="Services"
            ></AboutImg>{" "}
            <AboutTextWrapper>
              <AboutText>Data Analytics</AboutText>
              <ParaText>
                Data analysis empowers businesses by extracting valuable
                insights from vast datasets, guiding informed decision-making.
                It identifies trends, patterns, and opportunities, streamlining
                operations, optimizing strategies, and enhancing overall
                efficiency. In today's competitive landscape, leveraging data
                analysis is crucial for staying agile and gaining a competitive
                edge.
              </ParaText>
            </AboutTextWrapper>
          </AboutContainer>
        </LayoutMargin>
      </AboutWrapper>
      <LayoutMargin>
        <TextWrapper style={{ marginBottom: "4rem", marginTop: "7rem" }}>
          <h1>We provide a variety of Data Analytics services.</h1>
        </TextWrapper>
        <Container>
          {/* Add your dynamic divs here */}
          <Box>
            <TextWrapper>
              <h3>BI Dashboarding: </h3>
            </TextWrapper>
            <TextWrapper>
              <ParaText>
                Elevate your decision-making with captivating BI dashboards,
                transforming complex data into actionable insights, empowering
                your team to make informed choices for enhanced business growth.
              </ParaText>
            </TextWrapper>
          </Box>
          <Box>
            <TextWrapper>
              <h3>Reporting: </h3>
            </TextWrapper>
            <TextWrapper>
              <ParaText>
                Unleash the power of data through our detailed reports,
                delivering a strategic advantage by uncovering trends and
                patterns that propel your business towards unparalleled success.
              </ParaText>
            </TextWrapper>
          </Box>
          <Box>
            <TextWrapper>
              <h3>Cost Benefit Analysis: </h3>
            </TextWrapper>
            <TextWrapper>
              <ParaText>
                Maximize your profitability with meticulous cost-benefit
                analyses, providing a roadmap for prudent financial decisions
                and ensuring optimal returns on your investments.
              </ParaText>
            </TextWrapper>
          </Box>
          <Box>
            <TextWrapper>
              <h3>ROIC Analysis: </h3>
            </TextWrapper>
            <TextWrapper>
              <ParaText>
                Drive strategic excellence with ROIC analysis, unveiling the
                true impact of your investments and guiding your business
                towards sustainable growth and increased financial success.
              </ParaText>
            </TextWrapper>
          </Box>
          <Box>
            <TextWrapper>
              <h3>Data Pipelining: </h3>
            </TextWrapper>
            <TextWrapper>
              <ParaText>
                Revolutionize your data management with streamlined pipelines,
                ensuring the swift and efficient flow of information, fostering
                a data-driven culture for unparalleled operational excellence.
              </ParaText>
            </TextWrapper>
          </Box>
          <Box>
            <TextWrapper>
              <h3>Automation: </h3>
            </TextWrapper>
            <TextWrapper>
              <ParaText>
                Redefine efficiency through automation, freeing valuable
                resources from manual tasks, and enabling your team to focus on
                high-impact activities, resulting in heightened productivity and
                success.
              </ParaText>
            </TextWrapper>
          </Box>
        </Container>
      </LayoutMargin>
    </Wrapper>
  );
};

export default DataAnalytics;
