import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 2rem;

  @media (max-width: 930px) {
    display: none;
    margin-right: 0rem;
  }
`;
const IntroLink = styled(NavLink)`
  text-align: center;
  text-decoration: none;
  line-height: 2rem;
  font-weight: bold;
  font-size: 1.2rem;
  cursor: pointer;
  color: black;
  padding: 1rem 2.5rem;

  :hover {
    scale: 1.2;
  }
`;

const Navigation = () => {
  const handleLogoClick = () => {
    // useNavigate("/")
  };
  return (
    <LinkContainer>
      <IntroLink to="/home">Home</IntroLink>
      <IntroLink to="/about">About</IntroLink>
      <IntroLink to="/contact-us">Contact Us</IntroLink>
    </LinkContainer>
  );
};

export default Navigation;
