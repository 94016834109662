import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  width: ${(props) => (props.width === "fullWidth" ? "100%" : "")};
  padding: 0 3rem;

  @media (max-width: 700px) {
    padding: 0 0.5rem;
  }
`;

const LayoutMargin = (props) => {
  console.log(props.width);
  return <Wrapper width={props?.width}>{props.children}</Wrapper>;
};

export default LayoutMargin;
