import React from "react";
import styled, { keyframes } from "styled-components";
import contactUs from "../assets/contactUs.jpg";
import contactUs2 from "../assets/contactUs2.jpg";
import LayoutMargin from "../layout/LayoutMargin";

const Wrapper = styled.div`
  width: 100%;
  height: auto;
`;
const IntroWrapper = styled.div`
  width: 100%;
  height: 45vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: rgb(182 43 43);
  position: relative;
`;
const IntroContainer = styled.div`
  width: 100%;
  height: 70vh;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  @media (max-width: 930px) {
    flex-direction: column;
    height: 80vh;
    justify-content: center;
    align-items: flex-start;
  }
  @media (max-width: 530px) {
    height: 51vh;
  }
  @media (max-width: 410px) {
    height: 40vh;
  }
`;
const AboutContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  @media (max-width: 930px) {
    height: auto;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5rem;
  }
  @media (max-width: 530px) {
    margin-bottom: 1rem;
    padding-top: 1rem;
  }
`;
const slideInAnimation = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
`;
const slideInAnimationRTL = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;
const IntroText = styled.p`
  color: white;
  font-size: 3rem;
  margin-top: -1.5rem;
  position: absolute;
  bottom: 0;
  left: 5rem;
  font-weight: 600;
  z-index: 2;

  // animation: ${slideInAnimation} 1s ease-in-out;

  @media (max-width: 930px) {
    margin-top: 0;
    margin-left: 2rem;
    margin-bottom: 1rem;
  }
  @media (max-width: 530px) {
    margin-bottom: 1rem;
    font-size: 2.5rem;
  }
  @media (max-width: 410px) {
    margin-bottom: 0.5rem;
    font-size: 2rem;
  }
`;
const IntrImg = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  transform: scaleX(-1);

  // animation: ${slideInAnimationRTL} 1s ease-in-out;

  @media (max-width: 930px) {
    margin-left: 2rem;
  }
  @media (max-width: 530px) {
    width: 22rem;
  }
  @media (max-width: 410px) {
    width: 19rem;
  }
`;
const AboutText = styled.p`
  color: black;
  font-size: 3rem;
  margin-top: 0rem;
  margin-bottom: 0rem;
  animation: ${slideInAnimationRTL} 1s ease-in-out;

  @media (max-width: 930px) {
    order: 1;
  }
  @media (max-width: 410px) {
    font-size: 2rem;
  }
`;
const AboutImg = styled.img`
  height: auto;
  width: 30rem;
  animation: ${slideInAnimation} 1s ease-in-out;

  @media (max-width: 930px) {
    order: 2;
  }
  @media (max-width: 530px) {
    width: 22rem;
  }
  @media (max-width: 410px) {
    width: 19rem;
  }
`;
const AboutWrapper = styled.div`
  margin: 4rem 0;
  padding: 2rem 0;
  background-color: rgb(201 200 200 / 10%);

  @media (max-width: 530px) {
    margin: 0;
    padding-bottom: 6rem;
  }
  @media (max-width: 410px) {
    margin: 1rem 0;
  }
`;
const AboutTextWrapper = styled.div`
  margin: 2rem 2rem 2rem 0;
  max-width: 35rem;
  animation: ${slideInAnimationRTL} 1s ease-in-out;

  @media (max-width: 1200px) {
    margin: 2rem;
  }
  @media (max-width: 930px) {
    text-align: center;
    max-width: 45rem;
  }
`;
const IntroTextWrapper = styled(AboutTextWrapper)`
  animation: ${slideInAnimation} 1s ease-in-out;
`;
const ParaText = styled.p`
  font-size: 1.2rem;

  @media (max-width: 930px) {
    text-align: center;
  }
  @media (max-width: 410px) {
    font-size: 1rem;
  }
`;
const AboutMissionWrapper = styled.div`
  margin: 4rem 0;
  padding: 2rem 0;
  background-color: white;

  @media (max-width: 530px) {
    margin: 2rem 0;
  }
`;
const HelpWrapper = styled.div`
  width: 55%;
  animation: ${slideInAnimation} 1s ease-in-out;

  @media (max-width: 930px) {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 75%;
  }
`;
const AboutMissionText = styled.p`
  color: black;
  font-size: 3rem;
  margin-top: 0rem;
  margin-bottom: 0rem;

  @media (max-width: 530px) {
    margin-bottom: 1rem;
    font-size: 2.5rem;
  }
  @media (max-width: 410px) {
    margin-bottom: 0rem;
    font-size: 2rem;
  }
`;

const ContactUs = () => {
  return (
    <Wrapper>
      {/* <IntroWrapper>
        <IntroContainer>
          <IntroText>Contact Us</IntroText>
          <IntrImg
            src={contactUs}
            alt="Services"
          ></IntrImg>
        </IntroContainer>
      </IntroWrapper> */}
      <IntroWrapper>
        <IntroText>Contact Us</IntroText>
        <IntrImg
          src={contactUs}
          alt="Services"
        ></IntrImg>
      </IntroWrapper>
      <AboutWrapper>
        <LayoutMargin>
          <AboutContainer>
            <AboutImg
              src={contactUs2}
              alt="Services"
            ></AboutImg>
            <AboutTextWrapper>
              <AboutText>Contact Us to Level Up Your Digital Game!</AboutText>
              <ParaText>
                Ready to elevate your digital presence and embrace innovation?
                We're here to help. Whether you have questions about our
                services, want to discuss a potential collaboration, or simply
                seek advice on enhancing your digital strategy, we're just a
                message away.
              </ParaText>
            </AboutTextWrapper>
          </AboutContainer>
        </LayoutMargin>
      </AboutWrapper>
      <AboutMissionWrapper>
        <LayoutMargin>
          <AboutContainer style={{ marginBottom: "1rem" }}>
            <HelpWrapper>
              <AboutMissionText style={{ textAlign: "center" }}>
                How Can We Assist You?
              </AboutMissionText>
              <ParaText style={{ textAlign: "center" }}>
                Feel free to reach out for inquiries related to web development,
                machine learning solutions, data analytics, or any other
                technology-driven challenges you're facing. Our team of skilled
                engineers is enthusiastic about turning your ideas into reality
                and helping you stay ahead in the ever-evolving digital
                landscape. <span style={{ fontWeight: "600" }}>email:</span>{" "}
                contact@picktofa.com
              </ParaText>
            </HelpWrapper>
          </AboutContainer>
        </LayoutMargin>
      </AboutMissionWrapper>
    </Wrapper>
  );
};

export default ContactUs;
